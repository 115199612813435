<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BUNOS or 13th MONTH PAYSLIP</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col md="3" cols="12">
          <v-select
            v-model="year"
            class="mx-2"
            :items="year_items"
            item-value="year"
            item-text="year"
            label="Year Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" v-show="alert">
          <v-alert color="warning" text class="mb-0">
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{ alert_message }}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
        <!--        <v-col cols="12" md="3" v-if="search === '' && payroll_data.length > 0">-->
        <!--          <v-btn class="w-full" color="primary" @click="save_payment()" v-if="!saving_data"> Print-->
        <!--          </v-btn>-->
        <!--          <v-progress-circular :size="50" :width="5" color="primary" indeterminate-->
        <!--                               v-else></v-progress-circular>-->
        <!--        </v-col>-->
        <v-data-table dense :headers="headers" :items="payroll_data" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Payroll Data</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-text-field
                class="mx-2"
                :append-icon="icons.mdiAccountSearch"
                label="Search"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{
                payroll_data
                .map(function (x) {
                return x.id
                })
                .indexOf(item.id) + 1
                }}
              </td>
              <td>
                <v-icon
                  class="mr-2"
                  color="info"
                  @click="save_payment(payroll_data
                .map(function (x) {
                return x.id
                })
                .indexOf(item.id))"
                >
                  {{icons.mdiPrinter}}
                </v-icon>
              </td>
              <td>
                {{ item.employee.last_name + ',' + item.employee.first_name + ' ' +
                item.employee.middle_name }}
              </td>
              <td>
                {{ item.status }}
              </td>
              <td>
                {{ formatPrice(item.total_amount) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_late) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_sss) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_sss_loan) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_philhealth) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_pagibig) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_pagibig_loan) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_auto_loan) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_motor) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_coop_loan) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_loan) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_ca) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_plan) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_others) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_orec) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_coop_save) }}
              </td>
              <td>
                {{ formatPrice(item.deduction_tax) }}
              </td>
              <td>
                {{ formatPrice(item.net_amount) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiPrinter,
    mdiAccountSearch
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      alert_message: '',
      alert: false,

      duration: '',
      year: '',
      year_items: [],
      saving_data: false,

      payroll_data: [],
      search: '',
      headers: [
        {text: 'No.', value: 'id', sortable: false},
        {text: 'Print/Download', value: 'id', sortable: false},
        {text: 'Name', value: 'employee.last_name', sortable: false},
        {text: 'Status', value: 'bank_code_name', sortable: false},
        {text: 'Gross 13th Month Pay', value: 'bank_code_name', sortable: false},
        {text: 'Late', value: 'bank_code_name', sortable: false},
        {text: 'SSS', value: 'bank_code_name', sortable: false},
        {text: 'SSS Loan', value: 'bank_code_name', sortable: false},
        {text: 'PhilHealth', value: 'bank_code_name', sortable: false},
        {text: 'Pag-Ibig', value: 'bank_code_name', sortable: false},
        {text: 'Pag-Ibig Loan', value: 'bank_code_name', sortable: false},
        {text: 'Auto Loan', value: 'bank_code_name', sortable: false},
        {text: 'Motor Finance', value: 'bank_code_name', sortable: false},
        {text: 'Coop Loan', value: 'bank_code_name', sortable: false},
        {text: 'Acct Loan', value: 'bank_code_name', sortable: false},
        {text: 'CA', value: 'bank_code_name', sortable: false},
        {text: 'Plan', value: 'bank_code_name', sortable: false},
        {text: 'Others', value: 'bank_code_name', sortable: false},
        {text: 'OREC', value: 'bank_code_name', sortable: false},
        {text: 'Coop Save', value: 'bank_code_name', sortable: false},
        {text: 'Tax', value: 'bank_code_name', sortable: false},
        {text: 'Net', value: 'bank_code_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
          mdiPrinter,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', [
        'employee_id',
        'month_of',
        'address',
        'contact',
        'name',
        'department',
        'position',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',

        'bod_hilario_signature',
        'intan_signature',
        'maica_signature',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      ...mapActions('thirten_month_pay', ['thirten_payslip_data']),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.year_items = response.data[0].month_of
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        const data = new FormData()
        data.append('year', this.year)
        data.append('employee_id', this.employee_id)
        data.append('is_not_admin', this.position != 'ADMIN' && this.position != 'HUMAN RESOURCE' ? 1 : 0)
        this.thirten_payslip_data(data)
          .then(response => {
            this.payroll_data = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async save_payment(index) {
        this.saving_data = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = [260, 260]
        var addr = this.address
        var cont = this.contact
        var pos = this.position


        var bod_hilario_signature_ = this.bod_hilario_signature
        var intan_signature_ = this.intan_signature
        var maica_signature_ = this.maica_signature

        var title = '13th MONTH PAY'

        var salary_cover =
          this.year
        var item = this.payroll_data[index]
        if (item.is_bunos_only===1){
          title = 'CHRISTMAS BUNOS'
        }
        var array_data = []
        array_data.push([
          {text: title + ' DETAILS', alignment: 'center', style: 'main_info'},
          {text: 'Deductions', alignment: 'center', style: 'main_info'},
        ])
        array_data.push([
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'DETAILS ',
                    bold: true,
                  },
                  {
                    style: 'tableExample2',
                    text: 'AMOUNT ',
                    bold: true,
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                ],
              },
            ],
          },
        ])
        array_data.push([
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'GROSS ' + title,
                    bold: true,
                  },
                  {
                    style: 'tableExample2',
                    color: 'blue',
                    bold: true,
                    text: (item.total_amount / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'LATE',
                    bold: true,
                  },

                  {
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    color: 'red',
                    bold: true,
                    text: (item.deduction_late / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
        ])
        array_data.push([
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'ACCOUNTING LOAN',
                    bold: true,
                  },
                  {
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    color: 'red',
                    bold: true,
                    text: (item.deduction_loan / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
        ])
        array_data.push([
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'CASH ADVANCE',
                    bold: true,
                  },
                  {
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    color: 'red',
                    bold: true,
                    text: (item.deduction_ca / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
        ])
        array_data.push([
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'OTHERS',
                    bold: true,
                  },
                  {
                    style: 'tableExample2',
                    text: item.other_details,
                  },
                  {
                    style: 'tableExample2',
                    color: 'red',
                    bold: true,
                    text: (item.deduction_others / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
        ])
        array_data.push([
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'AUTO LOAN',
                    bold: true,
                  },
                  {
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    color: 'red',
                    bold: true,
                    text: (item.deduction_auto_loan / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
        ])
        array_data.push([
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'COOP LOAN',
                    bold: true,
                  },
                  {
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    color: 'red',
                    bold: true,
                    text: (item.deduction_coop_loan / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
        ])
        array_data.push([
          {
            text: '',
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'COOP SAVINGS',
                    bold: true,
                  },
                  {
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    color: 'red',
                    bold: true,
                    text: (item.deduction_coop_save / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
        ])
        array_data.push([
          {
            text: '',
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    bold: true,
                    text: 'MOTOR FINANCE',
                  },
                  {
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    color: 'red',
                    bold: true,
                    text: (item.deduction_motor / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
        ])
        array_data.push([
          {
            text: '',
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'TAX',
                    bold: true,
                  },
                  {
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    color: 'red',
                    bold: true,
                    text: (item.deduction_tax / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
        ])
        array_data.push([
          {
            text: '',
          },
          {
            columns: [
              {
                columns: [
                  {
                    style: 'tableExample2',
                    text: 'OREC',
                    bold: true,
                  },
                  {
                    text: '',
                  },
                  {
                    style: 'tableExample2',
                    color: 'red',
                    bold: true,
                    text: (item.deduction_orec / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  },
                ],
              },
            ],
          },
        ])
        if (item.is_bunos_only===0){
          array_data.push([
            {
              text: '',
            },
            {
              style: 'main_info',
              alignment: 'center',
              bold: true,
              text: 'Benefits Deductions',
            },
          ])
          array_data.push([
            {
              text: '',
            },
            {
              columns: [
                {
                  columns: [
                    {
                      style: 'tableExample2',
                      text: 'SSS',
                      bold: true,
                    },
                    {
                      text: '',
                    },
                    {
                      style: 'tableExample2',
                      color: 'red',
                      bold: true,
                      text: (item.deduction_sss / 1)
                        .toFixed(2)
                        .replace(',', '.')
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    },
                  ],
                },
              ],
            },
          ])
          array_data.push([
            {
              text: '',
            },
            {
              columns: [
                {
                  columns: [
                    {
                      style: 'tableExample2',
                      text: 'SSS LOAN',
                      bold: true,
                    },
                    {
                      text: '',
                    },
                    {
                      style: 'tableExample2',
                      color: 'red',
                      bold: true,
                      text: (item.deduction_sss_loan / 1)
                        .toFixed(2)
                        .replace(',', '.')
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    },
                  ],
                },
              ],
            },
          ])
          array_data.push([
            {
              text: '',
            },
            {
              columns: [
                {
                  columns: [
                    {
                      style: 'tableExample2',
                      text: 'PHILHEALTH',
                      bold: true,
                    },
                    {
                      text: '',
                    },
                    {
                      style: 'tableExample2',
                      color: 'red',
                      bold: true,
                      text: (item.deduction_philhealth / 1)
                        .toFixed(2)
                        .replace(',', '.')
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    },
                  ],
                },
              ],
            },
          ])
          array_data.push([
            {
              text: '',
            },
            {
              columns: [
                {
                  columns: [
                    {
                      style: 'tableExample2',
                      text: 'PAG-IBIG',
                      bold: true,
                    },
                    {
                      text: '',
                    },
                    {
                      style: 'tableExample2',
                      color: 'red',
                      bold: true,
                      text: (item.deduction_pagibig / 1)
                        .toFixed(2)
                        .replace(',', '.')
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    },
                  ],
                },
              ],
            },
          ])
          array_data.push([
            {
              text: '',
            },
            {
              columns: [
                {
                  columns: [
                    {
                      style: 'tableExample2',
                      text: 'PAG-IBIG LOAN',
                      bold: true,
                    },
                    {
                      text: '',
                    },
                    {
                      style: 'tableExample2',
                      color: 'red',
                      bold: true,
                      text: (item.deduction_pagibig_loan / 1)
                        .toFixed(2)
                        .replace(',', '.')
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    },
                  ],
                },
              ],
            },
          ])
          array_data.push([
            {
              text: '',
            },
            {
              columns: [
                {
                  columns: [
                    {
                      style: 'tableExample2',
                      bold: true,
                      text: 'LIFE PLAN',
                    },
                    {
                      text: '',
                    },
                    {
                      style: 'tableExample2',
                      color: 'red',
                      bold: true,
                      text: (item.deduction_plan / 1)
                        .toFixed(2)
                        .replace(',', '.')
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    },
                  ],
                },
              ],
            },
          ])
        }
        payments_array.push([
          {
            columns: [
              {image: imgData, width: 40, height: 34, style: 'logo'},
              {
                stack: [
                  'LABOTTEGA',
                  {text: addr, style: 'subheader2'},
                  {
                    text: cont,
                    style: 'subheader2',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          {
            text: title + ' SLIP',
            style: {
              fontSize: 15,
              alignment: 'center',
              bold: true,
            },
          },
          {text: ' '},
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'EMPLOYEE NAME: ',
                  {
                    text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
                    fontSize: 12,
                    bold: true,
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'YEAR OF: ',
                  {
                    text: salary_cover,
                    fontSize: 12,
                    bold: true,
                  },
                ],
              },
            ],
          },

          {text: ' '},
          {
            table: {
              widths: widths,
              body: array_data,
            },
            layout: {
              fillColor: function (rowIndex) {
                return rowIndex === 0 ? '#CCCCCC' : null
              },
            },
          },
          {text: ' '},
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'TOTAL GROSS: ',
                  {
                    text: (
                      (parseFloat(item.total_amount)) /
                      1
                    )
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    fontSize: 12,
                    color: 'blue',
                    bold: true,
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'TOTAL DEDUCTIONS: ',
                  {
                    text: (
                      (parseFloat(item.deduction_late) +
                        parseFloat(item.deduction_sss) +
                        parseFloat(item.deduction_philhealth) +
                        parseFloat(item.deduction_pagibig) +
                        parseFloat(item.deduction_loan) +
                        parseFloat(item.deduction_auto_loan) +
                        parseFloat(item.deduction_coop_loan) +
                        parseFloat(item.deduction_coop_save) +
                        parseFloat(item.deduction_ca) +
                        parseFloat(item.deduction_plan) +
                        parseFloat(item.deduction_others) +
                        parseFloat(item.deduction_tax) +
                        parseFloat(item.deduction_motor)) /
                      1
                    )
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    fontSize: 12,
                    color: 'red',
                    bold: true,
                  },
                ],
              },
              {
                style: 'main_info',
                alignment: 'center',
                text: [
                  'TOTAL NET ' + title + ': ',
                  {
                    text: (item.net_amount / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    fontSize: 15,
                    color: 'green',
                    bold: true,
                  },
                ],
              },
            ],
          },
          {
            stack: [
              {
                columns: [{text: ' '}],
              },
              {
                columns: [
                  {
                    text: 'PREPARED BY:',
                    style: 'main_data_2',
                    alignment: 'center',
                  },

                  {
                    text: 'PROCESS BY:',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: ' ',
                  },

                  {
                    text: ' ',
                  }
                ],
              },
              {
                columns: [
                  {
                    image: maica_signature_, width: 100, height: 50,
                    margin: [85, 0, 0, 0]
                  },
                  {
                    image: intan_signature_, width: 150, height: 50,
                    margin: [220, 0, 0, 0]
                  },

                ],
              },
              {
                columns: [
                  {
                    text: 'VALENTIN, PAULYN',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                  {
                    text: 'INTAN, ROLDAN C.',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'HR DEPARTMENT',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                  {
                    text: 'FINANCE DEPARTMENT',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_data_2',
                    text: 'APPROVED BY:',
                    alignment: 'center',
                  },
                ],
              },
              {
                columns: [
                  {
                    image: bod_hilario_signature_, width: 120, height: 50,
                    margin: [200, 0, 0, 0]
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'ALBERT M. HILARIO',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'EVP HEAD FINANCE',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                ],
              },
            ],
          },
        ])
        if ((index + 1) % 4 === 0) {
          payments_array.push([{text: ' '}, {text: ' '}, {text: ' '}, {text: ' '}, {text: ' '}])
        }
        // if (!Object.is(payment.length - 1, index)) {
        //   payments_array.push([
        //     {
        //       columns: [
        //         {
        //           text: ' ',
        //         },
        //       ],
        //       pageBreak: 'after',
        //     },
        //   ])
        // }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: payments_array,
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 15,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader3: {
              fontSize: 11,
            },
            subheader: {
              fontSize: 9,
            },
            subheader2: {
              fontSize: 8,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving_data = false
      },
    },
  }
</script>
